import React from 'react'
import { cx, css } from 'linaria'

import sound from '@/images/micro-sniff/sound.svg'
import { ReactComponent as SvgMicrophone } from '@/images/micro-sniff/microphone.svg'

const list = [
  'Download the latest version',
  'Unzip and drag it to the Applications folder',
  'Right click and press "Open" to launch',
]

export const ManualGuide = () => {
  return (
    <section
      className={cx(
        'section text-white mx-auto',
        css`
          max-width: 72.5rem;
          background: url(${sound}) no-repeat center center #001629;
          background-size: 100% auto;
        `
      )}
    >
      <div className={cx('container px-4')}>
        <div className="flex justify-between flex-wrap items-center -mx-4 flex-row-reverse">
          <div
            className={cx(
              'mb-5 md:mb-0 md:w-1/2 px-4 w-full md:w-1/2',
              css`
                max-width: 400px;
              `
            )}
          >
            <h3 className="mb-4 text-section-title">Manual Guide</h3>
            <ul className="list-decimal text-lg pl-5">
              {list.map((item, idx) => (
                <li key={item} className={cx(idx !== 0 && 'mt-6')}>
                  {item}
                </li>
              ))}
            </ul>
            <div
              className={cx(
                'text-sm mt-4',
                css`
                  color: #5c7385;
                `
              )}
            >
              *Works on macOS version 10.12 or later
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4 items-center justify-center">
            <SvgMicrophone width="180" viewBox="0 0 268 268" className="mx-auto" />
          </div>
        </div>
      </div>
    </section>
  )
}
