import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { cx, css } from 'linaria'

import { ReactComponent as CheckIconSvg } from '@components/svg/check-icon.svg'

const list = [
  'Opens at login - automatic and convenient',
  'Instantly notification - the real-time notice',
  'Overlay display - low key and avoid distraction',
  'Drag and drop - move it as you use',
]

export const Detector = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopLogo: file(relativePath: { eq: "micro-sniff/detector.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const sources = [data?.desktopLogo?.childImageSharp.fluid]
  return (
    <section
      className={cx(
        'section px-4 mx-auto',
        css`
          max-width: 72.5rem;
        `
      )}
    >
      <div className={cx('flex justify-end flex-wrap items-center -mx-4')}>
        <div className={cx('md:w-2/5 mb-5 md:mb-0 px-4')}>
          <h2 className="text-section-title mb-4">A detector at your home screen</h2>
          <ul className="list-reset">
            {list.map((item, idx) => (
              <li key={item} className={cx('flex', idx !== 0 && 'mt-2')}>
                <CheckIconSvg width="18" height="18" viewBox="0 0 24 24" className={cx('flex-none mt-1')} />
                <span
                  className={cx(
                    'block text-lg',
                    css`
                      margin-left: 18px;
                    `
                  )}
                >
                  {item}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div
          className={cx(
            'w-full md:w-3/5 px-4',
            css`
              max-width: 592px;
            `
          )}
        >
          <Img fluid={sources} alt="Modify as you use" className={cx('w-full')} />
        </div>
      </div>
    </section>
  )
}
