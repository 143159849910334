import React from 'react'
import { graphql } from 'gatsby'
import { cx, css } from 'linaria'

import { MicroSniffApp as AppDetail, microSniffVideoDemo } from '@components/contants'
import Seo from '@components/seo-detail-page'
import { Base } from '@ui/base'
import { Footer } from '@ui/footer'
import { Header } from '@ui/header'
import { CheckOn } from '@ui/check-on'
import { DownloadNow } from '@ui/download-now'

import { Jumbotron } from '@components/ui/jumbotron'
import banner from '@/images/micro-sniff/banner.jpg'
import boxPng from '@/images/micro-sniff/box.png'
import { Intro } from '@components/ui/intro'
import { Detector } from '@components/micro-sniff/detector'
import { ManualGuide } from '@components/micro-sniff/manual-guide'
import { CustomerReviews } from '@components/ui/customer-reviews'
import demoVideo from '@/assets/video/demo-micro-sniff.mp4'
import DemoOnMac from '@ui/demo-on-mac'

const MicroSniffDetailsPage = ({ data, path }) => {
  const images = data.allScreenshots.edges.map(({ node }) => node.childImageSharp.fixed.src)
  return (
    <>
      <Seo {...AppDetail} video={microSniffVideoDemo} images={images} />
      <Base>
        <Header className="top-0 sticky w-full z-50" />
        <Jumbotron
          {...AppDetail}
          bgClass={css`
            background: url(${banner}) no-repeat center center transparent;
            background-size: cover;
          `}
        />
        <Intro
          title="Remove the concern of sound privacy"
          details={[
            `A meeting is coming through. Your camera is off, but all of a sudden you forget to unplug the earphones which, 10 minutes ago, was still jamming on that youtube video you've been listening for hours. On other screens, your peers inevitably catch your sound through the mic. `,
            `Exactly, your microphone is being used by accident. We've all been there. `,
            `Micro Sniff is here to make sure that won't happen again.`,
          ]}
          className={css`
            background: linear-gradient(105.3deg, #ebf4fa -0.54%, #d7e7f5 50.25%, #e1e5f0 100%);
          `}
        />
        <div
          className={cx(
            'container px-4 relative z-10 md:pb-20 pb-10',
            css`
              margin-top: -20%;
            `
          )}
        >
          <DemoOnMac src={demoVideo} />
        </div>
        <Detector />
        <ManualGuide />
        <CheckOn {...AppDetail} />
        <CustomerReviews
          title="Customer Reviews"
          content="Based on 1 recent rating and reviews from"
          rating="5.0"
          seeAll={false}
          {...AppDetail}
        />
        <DownloadNow {...AppDetail} boxImage={boxPng} />
        <Footer path={path} />
      </Base>
    </>
  )
}

export const query = graphql`
  query {
    allScreenshots: allFile(filter: { absolutePath: { regex: "/micro-sniff/screenshots/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(jpegQuality: 100, fit: CONTAIN, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`

export default MicroSniffDetailsPage
